<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'App',
  created() {
    // const currentUrl = window.location.pathname;
    // var u = this.$store.getters.user;
    // if(u.Token !='' && (this.$store.getters.menuMap==null || this.$store.getters.menuMap.length == 0))
    //   this.getTableMapping();
    // console.log(currentUrl)
    // if(u.Token =='' || u.Id == null) 
    //   router.push({ path: '/login', query:{oldurl: currentUrl} });
    console.log(global.PatientId)
    
    var idMo = JSON.parse(localStorage.getItem("MoId"));
    if(global.PatientId =='' && this.$route.name != null ) 
      if(this.$route.fullPath != '/'+idMo )
        router.push({ path: '/'+idMo});
  },
  components: {
  }
}
</script>

<style>
</style>
