
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
// import mapping from './modules/mapping'


const store = new Vuex.Store({
  modules: {
    user,
    // mapping
  },
  getters
})

export default store