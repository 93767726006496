/* eslint-disable */
import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter);

//import { createWebHistory, createRouter } from "vue-router"

const router = new VueRouter ({
    //history:  createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/ChooseSpeciality',
            name: 'ChooseSpeciality',
            component: () => import("@/components/ChooseSpeciality")        
        },
        {
            path: '/ChooseResource',
            name: 'ChooseResource',
            component: () => import("@/components/ChooseResource")        
        },
        {
            path: '/ChooseDateTime',
            name: 'ChooseDateTime',
            component: () => import("@/components/ChooseDateTime")        
        },
        {
            path: '/ChoosePodr',
            name: 'ChoosePodr',
            component: () => import("@/components/ChoosePodr")        
        },
        {
            path: '/NotesPage',
            name: 'NotesPage',
            component: () => import("@/components/NotesPage")        
        },
        {
            path: '/:idMo',
            name: 'HomePage',
            component: () => import("@/components/HomePage")        
        }
    ]
});
export default router
/* eslint-disable */