import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios'
import moment from 'moment' 
import VueTouchKeyboard from "vue-touch-keyboard";
// import "vue-touch-keyboard/dist/vue-touch-keyboard.css"; // load default style
moment.locale('ru'); 
Vue.use(BootstrapVue);
Vue.use(VueTouchKeyboard);

global.axios = axios;
// global.server = "http://192.168.88.16:8668/"
global.server = "http://10.10.50.2/"
global.PatientId = ''
global.SpId = ''
global.npolicy = ''
global.MoName = ''
global.timer1 = []
global.uuid = ''
Vue.config.productionTip = false
// global.timer= moment.duration('00:03:00'),

new Vue({
  el: "#app",
  render: h => h(App),
  router,
  store,
}).$mount('#app')
