let userlocalStorage = window.localStorage.getItem('user');
const user = {
    state:userlocalStorage? JSON.parse(userlocalStorage):{
        BeginDate:null,
        BirthDate: null,
        Comment:null,
        DivisionName: null,
        DivisionTypeCode:null,
        DivisionTypeName:null,
        EmployeeId:null,
        ExpirationDate:null,
        FirstName:null,
        FrmoId:null,
        Id:null,
        LastName:null,
        LpuAddress:null,
        LpuFullName:null,
        LpuId:null,
        LpuName:null,
        Number:null,
        Oid:null,
        Patronymic:null,
        PositionCode:null,
        PositionFrmrCode:null,
        PositionId:null,
        PositionName:null,
        ProfilV002:null,
        PrvsV021:null,
        Snils:null,
        SpecialityCode:null,
        SpecialityId:null,
        SpecialityName:null,
        UserId: "",
        FIO: "",
        LpuOid: "",
        Token:"",
        Permission: []
    } ,
  
    mutations: {
      SET_USER: (state, data) => {
        for (let field in state) {
          if (data[field] != undefined) {
            state[field] = data[field]
          }
          if(field == "FIO" )
          state[field] = data.LastName + " " + data.FirstName[0] + " " + data.Patronymic[0]
          if(field == "Token"){
            window.localStorage.setItem('jwToken', data.Token)
          }
        }
        window.localStorage.setItem('user', JSON.stringify(state));
        //localStorage.setItem("user", JSON.stringify(state));
      },  
      CLEAR_USER: (state) => {
        for (let field in state) {
          if(field == "Permission" )
            state["Permission"] = []
          else 
          state[field] = ""
          if(field == "FIO" )
            state[field] = ""
        }
        window.localStorage.clear();
        //localStorage.setItem("user", JSON.stringify(state));
      },  
      SET_USER_PERMISSION: (state, data) => {
        console.log(data)
        state.Permission = data;
      },
      SET_USER_ID: (state, data) => {
        state.UserId = data;
      }
    },
  
    actions: {
      GET_USER_ID: async (context, payload)  =>{
        console.log(payload)
        await global.axios({
          url: "http://10.10.50.2" +  '/autorization', 
          method: 'get',
          params: payload
        }).then(async (response) => {
          if(response.data.length>0){
            await context.commit('SET_USER_ID', response.data)
          }
          else alert('Неверно указан логин и пароль. ');
        }).catch(() => {
        })
      },

      GET_USER_ID_jwt: async (context, payload)  =>{
        console.log(payload)
        await global.axios({
          url: /*"http://baikal-app1.ad.burmed.ru"*/ 'http://10.10.50.2' +  '/autorization?Thumbprint='+payload, 
          method: 'get'
        }).then(async (response) => {
          console.log(response.data)
          if(response.data.length>0){
            await context.commit('SET_USER_ID', response.data)
          }
          else alert('Неверно указан логин и пароль. ');
        }).catch(() => {
        })
      }
      /*async loginUser({ commit }, credentials) {
        // Authenticate user
        const response = await userService.authUser(credentials);
    
        commit("SET_USER_DATA", response.data);
      }*/
    }
  }
  
  export default user